<template>
  <a-card>
    <div>
      <span class="title">经销商问题统计</span>
      <span>经销商分组
        <span>{{isCommon == 1? '(公共分组)':'(私有分组)'}} </span>
       ： {{ detailData.groupName || "-" }}
      </span>
    </div>
    <div class="searchFrom">
      <a-form-model
          ref="searchFrom"
          :model="search_form"
          layout="inline"
          class="flex-wrap"
      >
        <a-form-model-item label="问题">
          <a-select
              v-model="search_form.problemStatus"
              placeholder="请选择"
              style="width: 230px"
          >
            <a-select-option
                :value="item.id"
                v-for="item in problemStatusList"
                :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="经销商">
          <a-input
              placeholder="经销商名称/编码/公司名"
              v-model.trim="search_form.dealerName"
              style="width: 250px"
          />
        </a-form-model-item>
        <a-form-model-item class="order-madel">
          <div>
            <a-button
                class="ml-10p"
                :icon="'redo'"
                @click="resetSearch"
                type="dashed"
            >重置
            </a-button>
            <a-button
                class="ml-10p"
                :type="'primary'"
                :icon="'search'"
                @click="searchClick"
            >
              查询
            </a-button>
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="table">
      <a-table
          :loading="loading"
          :columns="columns"
          :data-source="data"
          :row-key="(record, index) => index"
          :pagination="false"
          :scroll="{ x: 1000}"
          @change="handleTableChange"
      >
        <div slot="dealerInfo" slot-scope="text, record">
          <a-row>
            <a-col span="4"><span class="label_name">编码:</span></a-col>
            <a-col span="16">{{ record.dealerCode || "-" }}</a-col>
          </a-row>
          <a-row>
            <a-col span="4"><span class="label_name">大区:</span></a-col>
            <a-col span="16">{{ record.regionName || "-" }}</a-col>
          </a-row>
          <a-row>
            <a-col span="4"><span class="label_name">城市:</span></a-col>
            <a-col span="16">{{ record.cityName || "-" }}</a-col>
          </a-row>
        </div>
        <div class="operate" slot="operate" slot-scope="text, record">
          <router-link
              :to="{ name: 'dealerDetail',
              query: { dealer_id: record.dealerId,dealer_name: record.dealerName,tabActive:'5',diagnosticTabs:'1' } }"
              target="_blank"
          >
            查看详情
          </router-link>
        </div>
      </a-table>

      <BasePagination
          ref="basePagination"
          :currentPage="pagination.page"
          :pageSize="pagination.size"
          :total="pagination.total"
          @onChange="handlePaginationChange"
          @onShowSizeChange="handlePaginationChange"
      />
    </div>
  </a-card>
</template>

<script>
import api from "@/api/problem_table_list";
import { isEqual } from "lodash-es";

export default {
  name: "index",
  data() {
    return {
      loading: false,
      columns: [
        {
          title: "经销商名称",
          dataIndex: "dealerName",
          width: 240,
          customRender: (text) => {
            return text || "-";
          }
        },
        {
          title: "更多信息",
          dataIndex: "",
          scopedSlots: {customRender: "dealerInfo"},
          width: 240,
        },
        {
          title: "待确认问题",
          dataIndex: "toBeConfirmedCount",
          width: 100,
          customRender: (text) => {
            return text || 0;
          }
        },
        {
          title: "待解决/解决中问题",
          dataIndex: "resolvingCount",
          width: 160,
          customRender: (text) => {
            return text || 0;
          }
        },
        {
          title: "更新时间",
          dataIndex: "mtime",
          sorter: true,
          width: 200
        },
        {
          title: "操作",
          key: "operate",
          width: 130,
          // fixed: "right",
          scopedSlots: {customRender: "operate"}
        }
      ],
      data: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0
      },
      search_form: {
        dealerName: "",
        problemStatus: null,
        sort: "desc"
      },
      problemStatusList: [
        {
          id: null,
          name: "全部"
        },
        {
          id: 1,
          name: "待确认"
        },
        {
          id: 2,
          name: "待解决/解决中"
        },
      ],
      detailData: JSON.parse(this.$route.query.detailData) || {},
      isCommon: this.$route.query.isCommon || 1,
      id: this.$route.query.id || "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let params = {
        problemStatus: this.search_form.problemStatus,
        dealerName: this.search_form.dealerName,
        id: this.detailData.groupId,
        page: this.pagination.page,
        size: this.pagination.size,
        sort: this.search_form.sort,
        isCommon: this.isCommon
      };
      api.getList(params).then((res) => {
        if(res.code == 200) {
          console.log(res);
          this.data = res.data.list || [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.data = [];
          this.pagination.total = 0;
          this.$message.error(res.message || "获取数据失败");
        }
      });
    },
    handleTableChange(pagination, filters, sorter) {
      const {columnKey, order} = sorter;
      if(isEqual(columnKey, "mtime")) {
        const obj = {ascend: "descend", descend: "", def: "ascend"};
        const sortOrder = obj[order || "def"];

        this.columns = this.columns.map(v => ({...v, ...(isEqual(v.dataIndex, columnKey) ? {sortOrder} : {})}));
        this.search_form.sort = {ascend: "asc", descend: "desc"}[sortOrder] || "";
      }

      this.getList();
    },
    handlePaginationChange(page, size) {
      this.pagination.page = page;
      this.pagination.size = size;
      this.getList();
    },
    resetSearch() {
      this.search_form = {
        dealerName: "",
        problemStatus: null,
        sort: "desc"
      };
      this.pagination.page = 1;
      this.pagination.size = 10;
      this.getList();
    },
    searchClick() {
      this.pagination.page = 1;
      this.pagination.size = 10;
      this.getList();
    },
  }
};
</script>

<style scoped lang="less">
.title {
  font-size: 20px;
  font-weight: bold;
  padding-right: 30px;
}

.table {
  padding-top: 25px;
}

.label_name {
  font-weight: bold;
}

.searchFrom {
  padding: 20px 0;
}

.ml-10p {
  margin-left: 10px;
}
</style>
